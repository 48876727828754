<template>
  <div class="index-page">
    <n-loader :loading="$var('load')" />
    <div class="qr-wrap">
      <c-scanner :abletoscan="isAble" @scanned="(obj) => sendScan(obj)"></c-scanner>
    </div>
  </div>
</template>

<script>
import { Camera, } from '@capacitor/camera'
import { Geolocation, } from '@capacitor/geolocation'

export default {
  name: 'PageIndex',
  data() {
    return {
      geolocation: null,
      user: $app.auth.user(),
      isAble: false,
      tryCount: 0,
    }
  },
  computed: {},
  watch: {
    camera() {
      alert(this.camera)
    },
  },
  mounted() {
    this.getGeo()
  },
  methods: {
    sendScan(obj) {
      if (navigator.onLine) {
        this.$var('load', true)
        $api.visits.create(this.user.companyId, obj.branchId, this.geolocation).then(() => {
          this.$router.push({ name: 'success', })
        }).catch((error) => {
          const message = error.response.data.message
          this.$router.push({ name: 'error', params: { message: message, }, })
        }).finally(() => {
          this.$var('load', false)
        })
      } else {
        $app.store.action('app.setBuffer', {
          ...this.geolocation,
          branchId: obj.branchId,
        })
        this.$router.push({ name: 'warning', })
      }
    },
    getGeo() {
      if (this.tryCount < 2) {
        this.tryCount++
        $n.currentGeo().then((res) => {
          if (res !== false) {
            this.geolocation = res
            this.isAble = true
          } else {
            this.getGeo()
          }
        })
      }
    },
  },
}
</script>

<style lang="scss">
body {
  background: inherit !important;
}
</style>
